module.exports = {
  selectLanguage: "Select your language",
  displayName: "English",
  categories: "Categories",
  read: "Read",
  price: "Price",
  lastUpdate: "Last Update",
  free: "Now available for free",
  Software: "Software",
  "Machine Learning": "Machine Learning",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Tags",
  Home: "Home",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Contact Form",
  "Cancel": "Cancel",
  "Submit": "Submit",
  "Contact Message": "We are appreciated if you give us any feedback to our web site.",
  "Flutter": "Flutter"
}

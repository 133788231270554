import React from "react"

import Layout from "../layouts/en"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>We are afraid we don't have English version of this contents.</p>
  </Layout>
)

export default NotFoundPage
